var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ValidationProvider',{ref:"provider",staticClass:"geko-input",attrs:{"mode":"eager","name":_vm.item.label,"rules":_vm.item.validation ? _vm.item.validation.join('|') : ''},scopedSlots:_vm._u([{key:"default",fn:function({ classes, errors }){return [(!_vm.item.hide_label)?_c('label',{class:{
        required:
          Array.isArray(_vm.item.validation) &&
          _vm.item.validation.includes('required'),
      },attrs:{"for":_vm.item.view_data}},[_vm._v(_vm._s(_vm.item.label || "Tolong Gunakan Label Ya..."))]):_vm._e(),((_vm.item.type || 'text')==='checkbox'&&(['text', 'number'].includes(_vm.item.type)))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tmpValue),expression:"tmpValue"}],class:{
        invalid: errors.length > 0,
      },attrs:{"id":_vm.item.view_data,"placeholder":_vm.item.placeholder || '',"disabled":_vm.disabled,"type":"checkbox"},domProps:{"value":_vm.value,"checked":Array.isArray(_vm.tmpValue)?_vm._i(_vm.tmpValue,_vm.value)>-1:(_vm.tmpValue)},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"change":[function($event){var $$a=_vm.tmpValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.value,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.tmpValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.tmpValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.tmpValue=$$c}},function($event){return _vm.$emit('change', errors[0] ? '' : $event.target.value)}]}}):((_vm.item.type || 'text')==='radio'&&(['text', 'number'].includes(_vm.item.type)))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tmpValue),expression:"tmpValue"}],class:{
        invalid: errors.length > 0,
      },attrs:{"id":_vm.item.view_data,"placeholder":_vm.item.placeholder || '',"disabled":_vm.disabled,"type":"radio"},domProps:{"value":_vm.value,"checked":_vm._q(_vm.tmpValue,_vm.value)},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"change":[function($event){_vm.tmpValue=_vm.value},function($event){return _vm.$emit('change', errors[0] ? '' : $event.target.value)}]}}):(['text', 'number'].includes(_vm.item.type))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tmpValue),expression:"tmpValue"}],class:{
        invalid: errors.length > 0,
      },attrs:{"id":_vm.item.view_data,"placeholder":_vm.item.placeholder || '',"disabled":_vm.disabled,"type":_vm.item.type || 'text'},domProps:{"value":_vm.value,"value":(_vm.tmpValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.tmpValue=$event.target.value},function($event){return _vm.$emit('input', $event.target.value)}],"change":function($event){return _vm.$emit('change', errors[0] ? '' : $event.target.value)}}}):(['date', 'daterange'].includes(_vm.item.type))?_c('date-picker',{staticClass:"w-100",class:{
        invalid: errors.length > 0,
      },attrs:{"valueType":"format","range":_vm.item.type === 'daterange'},model:{value:(_vm.tmpValue),callback:function ($$v) {_vm.tmpValue=$$v},expression:"tmpValue"}}):(['textarea'].includes(_vm.item.type))?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.tmpValue),expression:"tmpValue"}],class:{
        invalid: errors.length > 0,
      },attrs:{"id":_vm.item.view_data,"type":_vm.item.type || 'text',"placeholder":_vm.item.placeholder || '',"disabled":_vm.disabled},domProps:{"value":_vm.value,"value":(_vm.tmpValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.tmpValue=$event.target.value},function($event){return _vm.$emit('input', $event.target.value)}],"change":function($event){return _vm.$emit('change', errors[0] ? '' : $event.target.value)}}}):(['select'].includes(_vm.item.type))?_c('geko-select',{staticClass:"vs-style",class:{
        invalid: errors.length > 0,
      },attrs:{"placeholder":_vm.item.placeholder || null,"disabled":_vm.disabled,"options":_vm.selectOptions,"reduce":(x) => x[_vm.item.option?.list_pointer.code || 'code'],"appendToBody":true,"label":_vm.item.option && _vm.item.option.list_pointer
          ? _vm.item.option.list_pointer.label
          : 'label',"searchable":typeof _vm.item.searchable === 'boolean' ? _vm.item.searchable : true,"multiple":_vm.item.option && _vm.item.option.multiple ? true : false},on:{"option:selected":function($event){return _vm.$emit('selected', $event)},"open":_vm.selectGetInitData,"search":_vm.selectSearchData},scopedSlots:_vm._u([{key:"no-options",fn:function({ search, searching, loading }){return [(_vm.isLoading)?_c('span',[_c('v-progress-circular',{staticClass:"mr-2",attrs:{"color":"success","indeterminate":"","size":17,"width":2}}),_c('span',{staticClass:"text-09-em text-grey"},[_vm._v(_vm._s(`Loading data ${_vm.item.label ? _vm.item.label.toLowerCase() : ""}...`))])],1):_c('span',[_vm._v(_vm._s(`Tidak ada data ${_vm.item.label ? _vm.item.label.toLowerCase() : ""}`))])]}}]),model:{value:(_vm.tmpValue),callback:function ($$v) {_vm.tmpValue=$$v},expression:"tmpValue"}}):(['editor'].includes(_vm.item.type))?_c('VueEditor',{class:{
        invalid: errors.length > 0,
      },attrs:{"disabled":_vm.disabled,"placeholder":_vm.item.placeholder || ''},model:{value:(_vm.tmpValue),callback:function ($$v) {_vm.tmpValue=$$v},expression:"tmpValue"}}):(['select-radio'].includes(_vm.item.type))?_c('div',{staticClass:"select-radio-row"},_vm._l((_vm.item.option.default_options),function(data,i){return _c('div',{key:'i' + _vm.item.view_data + i,staticClass:"select-radio-item",class:{
          active:
            _vm.tmpValue ===
            data[
              _vm.item.option && _vm.item.option.list_pointer.code
                ? _vm.item.option.list_pointer.code || 'code'
                : 'code'
            ],
        },on:{"click":function($event){return _vm.onSelectRadio(data)}}},[_c('v-icon',{staticClass:"select-radio-radio"},[_vm._v(_vm._s(data[ _vm.item.option && _vm.item.option.list_pointer ? _vm.item.option.list_pointer.code || "code" : "code" ] == _vm.tmpValue ? "mdi-radiobox-marked" : "mdi-radiobox-blank"))]),(data.icon)?_c('v-icon',{staticClass:"select-radio-icon"},[_vm._v(_vm._s(data.icon))]):_vm._e(),_c('span',[_vm._v(_vm._s(data.label))])],1)}),0):(['upload'].includes(_vm.item.type))?_c('input',{attrs:{"type":'file',"id":_vm.item.view_data,"accept":_vm.item.upload_type},on:{"change":function($event){return _vm.handleFileUpload($event)}}}):_vm._e(),(['upload'].includes(_vm.item.type))?_c('div',{staticClass:"upload-wrapper"},[_c('label',{staticClass:"file-upload",class:{
          uploaded: _vm.tmpImage,
        },style:({
          'background-image': _vm.tmpImage ? 'url(' + _vm.tmpImage + ')' : 'unset',
        }),attrs:{"for":_vm.item.view_data}},[(!_vm.tmpImage)?_c('v-icon',[_vm._v(_vm._s(_vm.item.option && _vm.item.option.icon ? _vm.item.option.icon : "mdi-image"))]):_vm._e()],1),_c('div',{staticClass:"upload-label"},[_c('h6',[_vm._v(" "+_vm._s(_vm.item.option ? _vm.item.option.label_hint : "Klik gambar untuk memilih berkas yang akan diunggah")+" ")]),(_vm.item.option && _vm.item.option.max_size)?_c('span',{staticClass:"d-block"},[_vm._v("Ukuran berkas maksimal "+_vm._s(_vm.item.option.max_size)+" Mb "),(_vm.item.option && _vm.item.option.max)?_c('span',[_vm._v("( Maksimal "+_vm._s(_vm.item.option.max)+" foto)")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"preview-image-list"},_vm._l((_vm.tmpImages),function(image,i){return _c('div',{key:_vm.item.label + i,staticClass:"preview-image",style:({
              'background-image': image ? 'url(' + image + ')' : 'unset',
            })},[_c('button',{on:{"click":function($event){return _vm.removeImage(i)}}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1)])}),0),(_vm.tmpImage)?_c('v-btn',{staticClass:"mt-2",attrs:{"variant":"danger","small":""},on:{"click":_vm.onRemoveImage}},[_vm._v(" Hapus ")]):_vm._e()],1)]):_vm._e(),(errors.length > 0)?_c('span',{staticClass:"error-message text-danger text-xs mt-1"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }