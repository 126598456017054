var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"geko-base-crud"},[_c('div',{staticClass:"d-flex flex-row geko-base-crud-header"},[_c('v-breadcrumbs',{staticClass:"breadcrumbsmain",attrs:{"dark":_vm.$store.state.theme == 'dark',"items":[
        {
          text: 'Utilities',
          disabled: true,
          href: 'breadcrumbs_dashboard',
        },
        {
          text: `${_vm.config.title}`,
          disabled: true,
          href: 'breadcrumbs_link_1',
        },
      ],"divider":">","large":"","data-aos":"fade-right"}}),(_vm.config.globalFilter && _vm.activeView === 'list')?_c('div',{staticClass:"global-filters d-flex flex-row"},[(_vm.config.globalFilter.project_purpose)?_c('geko-select',{staticClass:"vs-style v-select-40 no-clear min-w-150px mr-3",attrs:{"placeholder":"Tujuan Project","reduce":(x) => x.code,"options":[
          {
            label: 'Semua',
            code: '',
          },
          {
            label: 'Carbon',
            code: 'carbon',
          },

          {
            label: 'Non Carbon',
            code: 'non-carbon',
          },
        ]},on:{"option:selected":function($event){return _vm.setGlobalFilter('project_purpose', $event.code, 'tmpProjectPurpose')}},model:{value:(_vm.globalFilter.project_purpose),callback:function ($$v) {_vm.$set(_vm.globalFilter, "project_purpose", $$v)},expression:"globalFilter.project_purpose"}}):_vm._e(),(_vm.config.globalFilter.program_year)?_c('geko-select',{staticClass:"vs-style v-select-40 no-clear min-w-100px",attrs:{"placeholder":"Tahun","options":[
          {
            label: 'Semua',
            code: '',
          },
          {
            label: '2020',
            code: '2020',
          },
          {
            label: '2021',
            code: '2021',
          },
          {
            label: '2022',
            code: '2022',
          },
          {
            label: '2023',
            code: '2023',
          },
          {
            label: '2024',
            code: '2024',
          },
        ]},on:{"option:selected":function($event){return _vm.setGlobalFilter('program_year', $event.code, 'tmpProgramYear')}},model:{value:(_vm.globalFilter.tmpProgramYear),callback:function ($$v) {_vm.$set(_vm.globalFilter, "tmpProgramYear", $$v)},expression:"globalFilter.tmpProgramYear"}}):_vm._e()],1):_vm._e()],1),(_vm.activeView === 'list')?_c('div',{staticClass:"geko-list"},[_c('v-data-table',{staticClass:"rounded-xl elevation-6 mx-3 pa-1",attrs:{"headers":_vm.header,"items":_vm.data,"search":'',"data-aos":"fade-up","data-aos-delay":"100","data-aos-duration":"800","server-items-length":_vm.totalRecord,"items-per-page":_vm.perPage,"page":_vm.page,"footer-props":{
        itemsPerPageText: 'Jumlah Data Per Halaman',
        itemsPerPageOptions: [10, 25, 50, 100, 200],
        showCurrentPage: true,
        showFirstLastPage: true,
      },"loading":_vm.loading},on:{"update:page":($p) => (_vm.page = $p),"update:items-per-page":($p) => (_vm.perPage = $p)},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"geko-list-header px-5 py-3 mt-1"},[_c('div',{staticClass:"pr-5 mr-5"},[_c('h4',[_vm._v(_vm._s(_vm.config.title))])]),_c('div',{staticClass:"d-flex flex-row geko-list-header-action"},[_c('div',{staticClass:"geko-list-header-toolbar"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.getListData.apply(null, arguments)}}},[_c('v-text-field',{staticClass:"mr-2",attrs:{"append-icon":"mdi-magnify","label":"Pencarian","placeholder":'Cari data ' + _vm.config.title.toLowerCase(),"hide-details":"","dense":"","rounded":"","outlined":"","color":"green"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('button',{staticClass:"toolbar-button mr-2",on:{"click":_vm.onRefresh}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)]),_c('v-btn',{staticClass:"ml-4",attrs:{"variant":"success"},on:{"click":function($event){return _vm.$router.push({
                  query: {
                    view: 'create',
                  },
                })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_c('span',{staticClass:"ms-2"},[_vm._v("Tambah Data")])],1)],1),(_vm.config.statistic && _vm.statistic)?_c('div',{staticClass:"statistics"},_vm._l((_vm.statistic),function(stat,i){return (Array.isArray(_vm.statistic))?_c('div',{staticClass:"statistic-item",class:{
                [stat.color]: true,
              }},[_c('v-icon',[_vm._v(_vm._s(stat.icon))]),_c('div',{staticClass:"statistic-data"},[_c('p',{staticClass:"mb-0 label"},[_vm._v(_vm._s(stat.label))]),_c('p',{staticClass:"mb-0 value"},[_vm._v(_vm._s(stat.value))])])],1):_vm._e()}),0):_vm._e()])]},proxy:true},{key:"item.index",fn:function({ index }){return [_vm._v(" "+_vm._s((_vm.page - 1) * _vm.perPage + index + 1)+" ")]}},_vm._l((_vm.fields.list.filter(
          (x) => x.type === 'row-slot' || x.transform || x.class
        )),function(f){return {key:`item.${f.view_data}`,fn:function({ item }){return [(f.type === 'row-slot')?_vm._t('list-' + f.view_data,null,{"item":item}):_c('span',{class:{
            [typeof f.class === 'string' ? `d-block ${f.class}` : '']: true,
            [typeof f.class === 'object' && f.class !== null
              ? `d-block ${f.class[item[f.view_data]]}`
              : '']: true,
          }},[_vm._v(_vm._s(_vm._f("parse")(item[f.view_data],f.transform ? f.transform : "no-empty")))])]}}}),{key:"item.actions",fn:function({ item }){return [_c('div',{staticClass:"geko-list-actions-wrapper d-flex flex-column"},[_c('div',{staticClass:"geko-list-actions"},[_c('button',{staticClass:"geko-list-action-view",on:{"click":function($event){return _vm.$router.push({
                  query: {
                    view: 'detail',
                    id: item.id,
                  },
                  params: _vm.config.detail ? null : item,
                })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye-outline")])],1),_vm._t("list-action-update",function(){return [_c('button',{staticClass:"geko-list-action-update",on:{"click":function($event){return _vm.$router.push({
                    query: {
                      view: 'update',
                      id: item.id,
                    },

                    params: item,
                  })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil-minus")])],1)]},{"item":item}),(!_vm.hideDelete)?_vm._t("geko-list-action-delete",function(){return [(_vm.config.delete)?_c('button',{staticClass:"geko-list-action-delete",on:{"click":function($event){return _vm.onDelete(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-trash-can-outline")])],1):_vm._e()]}):_vm._e()],2),_c('div',{staticClass:"geko-list-actions-bottom"},[_vm._t("list-bottom-action",null,{"item":item})],2)])]}}],null,true)})],1):_vm._e(),(['create', 'update'].includes(_vm.activeView))?_c('div',{staticClass:"geko-form mx-4"},[_c('geko-base-form',{attrs:{"title":_vm.config.title,"fields":_vm.activeView === 'create' ? _vm.fields.create : _vm.fields.update,"setter":_vm.config.setter,"setterExtPayload":_vm.config.setter_ext_payload,"update":_vm.config.update,"updateExtPayload":_vm.config.update_ext_payload,"sort":_vm.config.formOption && Array.isArray(_vm.config.formOption.sort)
          ? _vm.config.formOption.sort
          : null,"update_id_getter":_vm.config.update_id_getter || 'id',"update_id_setter":_vm.config.update_id_setter || 'id'},on:{"success":_vm.getListData},scopedSlots:_vm._u([_vm._l((_vm.fields.create.filter((x) => x.type === 'row-slot')),function(f){return {key:`create-${f.view_data}`,fn:function({ formData, field, setFormData }){return [_vm._t('create-' + f.view_data,null,{"formData":formData,"field":field,"setFormData":setFormData})]}}}),_vm._l((_vm.fields.update.filter((x) => x.type === 'row-slot')),function(f){return {key:`update-${f.view_data}`,fn:function({ formData, field, setter }){return [_vm._t('update-' + f.view_data,null,{"formData":formData,"field":field,"setter":setter})]}}}),{key:"create-form",fn:function(){return [_vm._t("create-form")]},proxy:true}],null,true)})],1):(_vm.activeView === 'detail')?_c('div',{staticClass:"geko-base-detail mx-4"},[_vm._t("detail-row",function(){return [_c('geko-base-detail',{attrs:{"fields":_vm.fields.detail,"api":_vm.config.detail || '',"title":_vm.config.title},scopedSlots:_vm._u([{key:"detail-body",fn:function(){return [_vm._t("detail-body")]},proxy:true},_vm._l((_vm.fields.detail.filter((x) => x.type === 'row-slot')),function(f,i){return {key:`detail-row-${f.view_data}`,fn:function({ item }){return [_vm._t('detail-row-' + f.view_data,null,{"item":item})]}}}),_vm._l((_vm.fields.detail.filter((x) => x.type === 'slot')),function(f,i){return {key:`detail-${f.view_data}`,fn:function({ item }){return [_vm._t(`detail-${f.view_data}`,null,{"item":item})]}}})],null,true)})]})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }